import React from 'react'
import { Typography, Card } from "@mui/material";
import Layout from '../layouts';

const CoachingPage = () => (
    <Layout pageName="Coaching">
        <Card style={{padding:'20px'}}>
            <Typography variant="h6" style={{color:'#080',fontWeight:'bold'}}>Helene Cho is a <b>Certified
                Professional Coach</b> wth over 25 years of experience. She has coached over 500 clients maximizing
                performance and quality of life.</Typography>

            <h3 style={{textDecoration:'underline'}}>Executive Coaching:</h3>
            <ul>
                <li>Creating a Vision</li>
                <li>Strategic Planning</li>
                <li>Leadership Skills</li>
                <li>Branding</li>
                <li>Presentation Skills</li>
            </ul>

            <h3 style={{textDecoration:'underline'}}>Career Transition - Job Search Coaching:</h3>
            <ul>
                <li>Transition Assessment </li>
                <li>Resume</li>
                <li>Networking</li>
                <li>Interviewing</li>
                <li>Negotiating</li>
            </ul>

            <h3 style={{textDecoration:'underline'}}>Personal Coaching:</h3>
            <ul>
                <li>Life-Work Balance</li>
                <li>Personal Power Issues</li>
                <li>Spirituality</li>
                <li>Creativity</li>
                <li>Managing Transitions</li>
            </ul>

            <br/>
            <div>
                <blockquote style={{color:'#080',maxWidth:'600px',margin:'0 auto',textAlign:'center'}}>“I had the
                    opportunity to work with Helene during my transition period. She was my trusted counselor who helped
                    me to land a new job during a difficult economic time. Helene is positive, caring, compassionate and
                    accommodating. Whenever, I needed extra coaching, she made time for me. She celebrated with me during
                    my ups, and listened and supported me during my downs. Not only is Helene able to connect with people
                    on an individual basis, she is also able to adapt to helping many either through classes or networking
                    groups, and now through her new book.<br/><br/>
                    I highly recommend Helene for anyone looking for help navigating the current job market. She is
                    knowledgeable about every facet from resumes, profiles, networking, interview questions, negotiations,
                    etc. She will work with you to make you successful!<br/><br/>
                    <b>Top qualities: Great Results, Personable, High Integrity</b>”
                    <br/><br/><small><em>- Miyoko Minegishi</em></small></blockquote>
            </div>

        </Card>
    </Layout>
);

export default CoachingPage;
